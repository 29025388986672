import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "material-ui-snackbar-provider";
import "typeface-roboto";
import AppBar from "./AppBar";
import NavigationDrawer from "./NavigationDrawer";
import logo from "./logo.svg";
import storemasterLogo from "./storemaster.png";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { LogoutVariant } from "mdi-material-ui";
import { useRegistry } from "../../plugins/registry";
import { White } from "../../../components/White";
import {
  useArticleType,
  useEmployee,
  useUser,
} from "../context/applicationContext";
import SetupDialog from "./SetupDialog";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import ChangelogDialog from "./Changelog/ChangelogDialog";
import { addOfflineListener } from "../api";
import TooltipIconButton from "../../../components/TooltipIconButton";

const styles = (theme) => ({
  colorHeader: {
    position: "fixed",
    width: "100%",
    height: 240,
    top: 0,
    left: 0,
  },
  background: {
    marginTop: -64,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    minHeight: "calc(100vh - 64px - 32px)",
    flex: 1,
    position: "relative",
    margin: "64px auto 0",
    width: "100%",
  },
  footer: {
    height: 32,
    lineHeight: "32px",
    margin: "0 16px",
    color: theme.palette.text.secondary,
    display: "flex",
  },
  menu: {
    marginTop: 16,
  },
  title: {
    fontWeight: 400,
    marginRight: 24,
  },
  light: {
    fontWeight: 300,
  },
  typeSelect: {
    marginRight: 24,
    width: 130,
  },
  employee: {
    width: 130,
    marginRight: "auto",
    "& input": {
      // fix Chrome autofill blue background
      boxShadow: `0 0 0 1000px inset ${theme.palette.primary.main}`,
      WebkitTextFillColor: "#fff !important",
    },
    "& label": {
      // fix label being under the shadow (regression introduced by the autofill fix)
      zIndex: 1,
      pointerEvents: "none",
    },
  },
  storemaster: {
    background:
      "linear-gradient(75deg, transparent 20px, #fff 21px, #fff 100%)",
    padding: "16px 24px 16px 32px",
    marginRight: -24,
    "& img": {
      display: "block",
      height: 32,
    },
  },
  flex: {
    flex: 1,
  },
  offlineBanner: {
    background: theme.palette.grey[800],
    color: "#fff",
    padding: "4px 8px",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 500,
  },
  textLink: {
    cursor: "pointer",
  },
});

function AppFrame({
  activeRoute,
  children,
  classes,
  onChangeRoute,
  authStatus,
  onAuthStatusChange,
  onLogout,
}) {
  const { t } = useTranslation();
  const registry = useRegistry();
  const [type, setType] = useArticleType();
  const [employee, setEmployee] = useEmployee();
  const [employeeInput, setEmployeeInput] = React.useState(employee);
  const user = useUser();

  const [showEmployeeDialog, setShowEmployeeDialog] = React.useState(
    employee === "" || employee == null
  );

  const handleChangeType = (e) => {
    if (e.target.value !== type) {
      setType(e.target.value);
      if (activeRoute.indexOf("Stock/") === 0) {
        onChangeRoute("Stock");
      }
    }
  };

  const [offline, setOffline] = React.useState(false);
  useEffect(() => {
    const cleanup = addOfflineListener((offline, lastUpdated) => {
      setOffline(offline ? lastUpdated : false);
    });
    return () => {
      cleanup();
    };
  });

  const handleLogout = useCallback(() => {
    setEmployeeInput("");
    setEmployee(null);
    onLogout();
    setShowEmployeeDialog(true);
  }, [onLogout, setEmployee]);

  useEffect(() => {
    if (authStatus === "unauthenticated" && employee == null) {
      // explicitly logged out
      setEmployeeInput("");
      setShowEmployeeDialog(true);
    }
  }, [authStatus, employee]);

  const [showChangelog, setShowChangelog] = useState(
    localStorage.getItem("changelog") !== process.env.REACT_APP_VERSION
  );

  const handleHideChangelog = useCallback(() => {
    setShowChangelog(false);
    localStorage.setItem("changelog", process.env.REACT_APP_VERSION);
  }, []);

  const handleAuthStatusChange = useCallback(
    (status, user) => {
      if (status === "authenticated" && user?.employee) {
        // user with employee name logged in
        setEmployee(user.employee);
        setShowEmployeeDialog(false);
      }
      onAuthStatusChange(status, user);
    },
    [setEmployee, onAuthStatusChange]
  );

  const visibleArticleTypes = useMemo(
    () =>
      Object.values(registry.articleTypes).filter(
        (articleType) => user?.features?.articleTypes[articleType.id] !== false
      ),
    [registry, user]
  );

  useEffect(() => {
    if (!visibleArticleTypes.some((articleType) => articleType.id === type)) {
      setType(visibleArticleTypes[0].id);
    }
  }, [type, setType, visibleArticleTypes]);

  return (
    <>
      {offline && (
        <div className={classes.offlineBanner}>
          {t(
            "Die Verbindung zum Server wurde unterbrochen, Sie befinden sich daher im Nur-Lese-Modus. Die angezeigten Daten wurden am {{updateTime}} aktualisiert.",
            { updateTime: new Date(offline).toLocaleString() }
          )}
        </div>
      )}
      <AppBar
        leftIcon={<img src={logo} width={32} height={32} alt="Logo" />}
        title={
          <span className={classes.title}>
            StoreKeeper <span className={classes.light}>Smart</span>
          </span>
        }
      >
        <White>
          {visibleArticleTypes.length > 1 && (
            <FormControl className={classes.typeSelect} margin="dense">
              <InputLabel htmlFor="articleType">{t("Artikeltyp")}</InputLabel>
              <Select
                value={type}
                onChange={handleChangeType}
                inputProps={{
                  name: "articleType",
                  id: "articleType",
                }}
              >
                {visibleArticleTypes
                  .sort((a, b) =>
                    a
                      .displayName(2, { t })
                      .localeCompare(b.displayName(2, { t }))
                  )
                  .map((articleType) => (
                    <MenuItem key={articleType.id} value={articleType.id}>
                      {articleType.displayName(2, { t })}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          <TextField
            className={classes.employee}
            label={t("Mitarbeiterkürzel")}
            placeholder={t('z.B. "dp"')}
            margin="dense"
            value={user?.employee || employeeInput}
            onChange={(e) => setEmployeeInput(e.target.value)}
            onBlur={(e) => setEmployee(employeeInput)}
            disabled={!!user?.employee}
          />
          {user && (
            <TooltipIconButton tooltip={t("Abmelden")} onClick={handleLogout}>
              <LogoutVariant />
            </TooltipIconButton>
          )}
          <div className={classes.flex} />
          <FormControl className={classes.typeSelect} margin="dense">
            <InputLabel htmlFor="articleType">Language</InputLabel>
            <LanguageSelector />
          </FormControl>
        </White>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://storemaster.de/"
          className={classes.storemaster}
        >
          <img src={storemasterLogo} alt="Logo" />
        </a>
      </AppBar>
      <div className={classes.background}>
        <div className={classes.contentWrapper}>
          <NavigationDrawer
            classes={{ menu: classes.menu }}
            activeRoute={activeRoute}
            onChangeRoute={onChangeRoute}
            additionalStockItemTypes={Object.values(registry.stockItemTypes)
              .filter(
                (stockItemType) =>
                  stockItemType.articleType === type &&
                  stockItemType.icon != null
              )
              .map((stockItemType) => ({
                name: stockItemType.displayName(2, { t }),
                icon: <stockItemType.icon />,
                route: `Stock/${stockItemType.id}`,
              }))}
            additionalMenuItems={registry.menuItems}
            additionalSettingsMenuItems={registry.settingsMenuItems}
          />
          {children}
        </div>
        <Typography
          component="div"
          className={classes.footer}
          variant="caption"
        >
          StoreKeeper Smart v{process.env.REACT_APP_VERSION} (
          <span
            className={classes.textLink}
            onClick={() => setShowChangelog(true)}
          >
            {t("Was ist neu?")}
          </span>
          ) – © 2019-2024 storemaster GmbH &amp; Co. KG
          <div className={classes.flex} />
          <ComponentExtensionPoint name="footerRight" variant="list" />
        </Typography>
      </div>
      <SetupDialog
        open={
          (showEmployeeDialog || authStatus === "unauthenticated") &&
          authStatus !== "unknown"
        }
        onClose={() => setShowEmployeeDialog(false)}
        onSetEmployee={(newEmployee) => {
          setEmployee(newEmployee);
          setEmployeeInput(newEmployee);
          setShowEmployeeDialog(false);
        }}
        authStatus={authStatus}
        onAuthStatusChange={handleAuthStatusChange}
      />
      <ChangelogDialog open={showChangelog} onClose={handleHideChangelog} />
    </>
  );
}

export default withSnackbar()(withStyles(styles)(AppFrame));
